import React from "react";
import Layout from "../Layout";
import SEO from "../seo";
import "./partner.scss";

const PartnerPage = ({ location }) => {
  return (
    <Layout>
      <SEO
        title="Corporate Partnership | Chinmaya Mission"
        description="Run for a good cause and showcase your organisation's commitment towards giving back. Become a corporate partner today."
        keywords="Chinmaya Mission, corporate social responsibility, CCMT, charity, "
        img={`https://${location.host}/link-preview.jpg`}
      />
      <div className="partner">
        <div className="content">
          <h1>Corporate Teams</h1>
          <div className="divider"></div>
          <br />
          <p>
            Participating in the Tata Mumbai Marathon on behalf of the Chinmaya
            Mission is a unique and effective way to showcase your
            organization’s commitment to giving back.
            <br />
            <br />
            Running together is more fun - and participating with your
            colleagues can really speed up social impact.
            <br />
            <br />
            Make the most of sponsorship opportunities for this spectacular
            community event by signing up early.
          </p>
        </div>
        <div className="registration">
          <h2>Registration Details</h2>

          <p>
            Corporates can participate by pre-purchasing team entries and then
            register individual team members.
          </p>
          <div className="registration-card">
            <ul>
              <li> Team of 15 for INR 3,45,000/-</li>
              <li> Team of 25 for INR 5,50,000/-</li>
              <li> Team of 40 for INR 8,40,000/-</li>
              <li> Team of 100 for INR 20,00,000/-</li>
            </ul>
            <p>
              *Add-on team of 5 for INR 1,20,000.
              <br />
              *Race fees are additional depending on the category of Run
              employee wishes to choose.
            </p>
          </div>
        </div>

        <div className="bottom-panel">
          <p>
            Kudos to our corporate team participants for ushering in positive
            change!
          </p>
          <a className="btn" href="/contact/">
            Sign up for next year
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default PartnerPage;
